<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
        class="text-left mt-2"
      >
        <v-icon
          large
          class="mb-4"
        >
          mdi-office-building
        </v-icon>
        <span class="text-h2">Stakeholders</span>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          v-if="$store.state.Permissions.StakeholderCreate"
          color="primary"
          fab
          large
          @click="createStakeholderDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="my-0 py-0"
      >
        <stakeholder-tree-view ref="StakeholderTreeView" />
      </v-col>
    </v-row>
    <v-dialog
      v-model="cropperDialog"
      width="600"
    >
      <v-card class="pa-4">
        <v-col cols="12">
          <cropper
            ref="cropper"
            class="cropper"
            :src="imgUrl"
            :stencil-component="$options.components.RectangleStencil"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            @click="cropImage"
          >
            save
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createStakeholderDialog"
      width="600"
    >
      <v-card>
        <v-card-title
          class="white--text primary"
        >
          Create Stakeholder
        </v-card-title>
        <v-form
          ref="form"
          v-model="valid"
          class="overflow-hidden"
        >
          <v-row class="pa-4">
            <v-col
              cols="12"
              class="text-center"
            >
              <v-avatar
                size="180"
                tile
              >
                <img
                  style="cursor:pointer;"
                  :src="
                    profilePictureURL === null
                      ? 'contact-placeholder.jpg'
                      : profilePictureURL
                  "
                  @click="$refs.stakeholderLogoUpload.click()"
                >
              </v-avatar>
              <input
                ref="stakeholderLogoUpload"
                style="display:none;"
                type="file"
                accept="image/*"
                @change="addNewImgToCropper"
              >
            </v-col>
            <!-- <v-col cols="9"> -->
            <!-- <v-file-input
                outlined
                accept="image/*"
                v-model="uploadedStakeholderLogo"
                label="Stakeholder Logo"
                @change="addNewImgToCropper"
                :clearable="false"
              /> -->

            <!-- </v-col> -->
            <v-col cols="6">
              <v-text-field
                v-model="name"
                outlined
                label="Name*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="primaryRole"
                outlined
                :items="primaryRoleOptions"
                label="Primary Role*"
                :rules="requiredRule"
                item-text="name"
                :item-value="'name'"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="address1"
                outlined
                label="Address Line 1*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="address2"
                outlined
                label="Address Line 2"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="city"
                outlined
                label="City*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="postcode"
                outlined
                label="Postcode*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="countryCode"
                outlined
                :items="countryCodeOptions"
                item-text="name"
                :item-value="'code'"
                label="Country*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="addNewStakeHolder"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import StakeholderTreeView from '@/components/stakeholders/TreeView'
  import StakeholderController from '@/services/controllers/Stakeholder'
  import CampaignController from '@/services/controllers/Campaign'
  import countryPhonePrefixes from '../../../../public/country-phone-prefix.json'
  import { Cropper } from 'vue-advanced-cropper'

  export default {
    components: {
      StakeholderTreeView,
      Cropper,
    },
    
    data: () => ({
      createStakeholderDialog: false,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'friendlyName',
        },
        {
          text: 'Primary Role',
          align: 'start',
          sortable: true,
          value: 'primaryRole',
        },
        {
          text: 'Country',
          align: 'start',
          sortable: true,
          value: 'address.countryCode',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      stakeholders: [],
      stakeholderAddError: null,
      profilePictureURL: null,
      mediaUploadId: null,
      imgUrl: null,
      cropperDialog: false,
      croppedImgB64: null,
      uploadedStakeholderLogo: null,
      name: null,
      primaryRole: null,

      primaryRoleOptions: [],
      valid: false,
      requiredRule: [(v) => !!v || 'Field is required'],
      address1: null,
      address2: null,
      city: null,
      postcode: null,
      countryCode: null,
      countryCodeOptions: countryPhonePrefixes,
    }),

    created () {
      StakeholderController.getPrimaryRoles().then((res) => {
        this.primaryRoleOptions = res.data
      })
    },

    methods: {
      cropImage () {
        const { canvas } = this.$refs.cropper.getResult()
        let resultFromApi
        var checkForResult = setInterval(() => {
          if (resultFromApi !== undefined) {
            clearInterval(checkForResult)
            this.mediaUploadId = resultFromApi.id
            this.profilePictureURL = resultFromApi.url
            this.imgUrl = null
            this.cropperDialog = false
          }
        }, 100)
        if (canvas) {
          const form = new FormData()
          canvas.toBlob((blob) => {
            // form.append("file", blob);
            var reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              var base64data = reader.result

              form.append('fileBase64', base64data)
              form.append('campaignId', 0)

              CampaignController.uploadB64(form)
                .then((b64res) => {
                  resultFromApi = b64res.data
                })
                .catch((err) => {
                  clearInterval(checkForResult)
                })
            }
          }, 'image/png')
        }
      },

      addNewImgToCropper (e) {
        this.uploadedStakeholderLogo = Object.values(e.target.files)[0]
        this.imgUrl = URL.createObjectURL(this.uploadedStakeholderLogo)
        this.cropperDialog = true
      },

      addNewStakeHolder () {
        this.$refs.form.validate()
        if (this.valid === true) {
          StakeholderController.addStakeholder({
            name: this.name,
            address: {
              countryCode: this.countryCode,
              addressLine1: this.address1,
              addressLine2: this.address2,
              city: this.city,
              postcode: this.postcode,
            },
            primaryRole: this.primaryRole,
            logoId: this.mediaUploadId === null ? 0 : this.mediaUploadId,
          })
            .then((res) => {
              this.name = null
              this.countryCode = null
              this.address1 = null
              this.address2 = null
              this.city = null
              this.postcode = null
              this.primaryRole = null
              this.mediaUploadId = null
              this.uploadB64 = null
              this.imgUrl = null
              this.valid = false
              this.uploadedStakeholderLogo = null
              this.profilePictureURL = null
              this.$root.$emit(
                'snackbarSuccess',
                'Stakeholder added successfully.',
              )
              // Refresh stakeholders in table view
              this.$refs.StakeholderTreeView.getAllStakeholders()
              //
              this.createStakeholderDialog = false
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        }
      },

      genRandomIndex (length) {
        return Math.ceil(Math.random() * (length - 1))
      },
    },
  }
</script>
<style>
.cropper {
  height: 600px;
}
</style>
